<template>
  <div>
    <div class="h c" v-if="target">
      <div>
        <div class="h c">
          <form-info-item label-position="right" label-suffix=":" label="销售单号" v-if="!mall">
            <b>{{target.formCode || "-"}}</b>
          </form-info-item>&nbsp;&nbsp;&nbsp;&nbsp;
          <form-info-item label-position="right" label-suffix=":" label="合同号" v-if="!mall">
            <b>{{target.contractNo || "-"}}</b>
          </form-info-item>
        </div>
        <form-info-item label-position="right" label-suffix=":" label="门店" v-if="!mall">
          <b>{{target.shopName || "-"}}</b>
        </form-info-item>
      </div>
      <div class="flex"></div>
      <div class="money-count-item">
        <div class="fc-g">应收金额</div>
        <counter class="num" :end-val="calcTotal / 100" :decimals="2" :duration="1000" />
      </div>
      <div class="money-count-item">
        <div class="fc-g">已收金额</div>
        <counter class="num" :end-val="calcPaied / 100" :decimals="2" :duration="1000" />
      </div>
      <div class="money-count-item">
        <div class="fc-g">待收金额</div>
        <counter class="num" :end-val="calcRemain / 100" :decimals="2" :duration="1000" />
      </div>
      <div class="money-count-item">
        <div class="fc-g">其他费用</div>
        <counter class="num" :end-val="calcOthers / 100" :decimals="2" :duration="1000" />
      </div>
    </div>

    <el-table row-key="id" :data="data" border empty-text="该销售单还没有支付款项" v-loading="loading" style="margin: 10px 0;">
      <el-table-column label="收/退款单号" prop="code" min-width="130" />
      <el-table-column label="费用类型" :formatter="r => { return feeTypes[r.feeType] }" min-width="80" />
      <el-table-column label="支付日期" :formatter="r => { return new Date(r.payTime).format('yyyy/MM/dd'); }" min-width="80" />
      <el-table-column label="支付类型" :formatter="r => { return r.amount >= 0 ? '付款' : '退款' }" min-width="80" />
      <el-table-column prop="amount" label="支付金额" :formatter="$price" min-width="100" align="right" />
      <el-table-column label="渠道" min-width="60" :formatter="r => {return payChannels[r.payChannel]; }" prop="payChannel" />
      <el-table-column prop="payType" label="支付方式" width="80" />
      <el-table-column label="已审核" width="70" align="center">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.finCheck"></i>
        </template>
      </el-table-column>
      <el-table-column label="已到账" width="70" align="center">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.inAccount"></i>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="支付备注" min-width="100" />
      <el-table-column width="130" align="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="view(scope.row)">{{scope.row.finCheck ? "查看" : "编辑"}}</el-button>
          <el-button v-permission="['FINANCE_ALL','FINANCE_MANAGER']" size="mini" type="text" @click="view(scope.row, 'audit')" v-if="!scope.row.finCheck">审核</el-button>
          <el-popover :ref="scope.row._id" placement="top" width="180" v-if="editable && !(scope.row.finCheck || scope.row.inAccount)">
            <p>确定删除该支付款项吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row._id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="remove(scope.row)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
          <el-button v-permission="['FINANCE_ALL','FINANCE_MANAGER']" size="mini" type="text" class="danger" @click="redoAudit(scope.row)" v-if="editable && scope.row.finCheck && !scope.row.inAccount">取消审核</el-button>
        </div>
      </el-table-column>
    </el-table>

    <template v-if="editable">
      <el-button type="primary" icon="el-icon-plus" @click="view(null)">添加支付款项</el-button>
      <el-button type="danger" icon="el-icon-sort" @click="transfer">转移支付单</el-button>
    </template>

    <pay-form ref="form" @remote-submit="handleSubmit" @localc-submit="handleSubmit" />
    <pay-transfer ref="transfer" :order="target" @complete="toQuery" v-if="target" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { del, rollbackAudit } from "@/api/shopPay";
import payForm from "./pay-form";
import payTransfer from "./pay-transfer";
import counter from "vue-count-to";

export default {
  components: { payForm, payTransfer, counter },
  mixins: [initData],
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    mall: Boolean,
    temps: Array,
    target: Object,
  },
  watch: {
    target: {
      deep: true,
      handler: "toQuery",
    },
  },
  data() {
    return {
      loading: false,
      payChannels: {
        mall: "商场",
        store: "POS",
        enterprise: "企业",
        app: "APP",
        change: "转移",
      },
      feeTypes: {
        orderForm: "订单费用",
        freight: "运费",
        repair: "维修费",
        rebate: "返利",
        other: "其他",
      },
    };
  },
  computed: {
    calcTotal() {
      if (this.target) {
        let m = 0;
        if (this.target.hasOwnProperty("_totalAmount")) {
          m = this.target._totalAmount || 0;
        } else {
          m = this.target.realTotalMoney;
        }
        if (m < 0 || isNaN(m)) m = 0;
        return m;
      }
      return 0;
    },
    calcPaied() {
      let c = 0;
      if (this.data && this.data.length) {
        this.data.forEach((d) => {
          if (d.feeType === "orderForm") c += d.amount || 0;
        });
      }
      return c;
    },
    calcRemain() {
      let r = this.calcTotal - this.calcPaied;
      // if (r < 0) r = 0;
      return r;
    },
    calcOthers() {
      let c = 0;
      if (this.data && this.data.length) {
        this.data.forEach((d) => {
          if (d.feeType !== "orderForm") c += d.amount || 0;
        });
      }
      return c;
    },
  },
  methods: {
    checkPermission,
    beforeInit() {
      if (this.target) {
        this.url = "api/shop/form/pay/" + this.target.id;
        return true;
      } else {
        this.data = this.temps;
        return false;
      }
    },
    afterLoad(res) {
      if (res && res.length) {
        res.forEach((o) => {
          o._id = this.$uuid();
          o.editable = false;
        });
      }
    },
    handleSubmit(data) {
      this.toQuery();
      this.$emit("pay-completed");
    },
    remove(data) {
      let _fn = (_) => {
        let inx = this.data.findIndex((o) => {
          return o._id === data._id;
        });
        if (inx >= 0) this.data.splice(inx, 1);
        this.$emit("pay-completed");
      };
      if (data.id) {
        this.delLoading = true;
        del(data.id)
          .then((_) => {
            this.$message({
              type: "success",
              message: "删除支付款项成功!",
            });
            _fn();
          })
          .finally((_) => {
            this.delLoading = false;
          });
      } else {
        _fn();
      }
    },
    transfer() {
      this.$refs.transfer && this.$refs.transfer.open();
    },
    view(data, action) {
      this.$refs.form &&
        this.$refs.form.resetForm(
          this.target,
          JSON.parse(JSON.stringify(data)),
          action
        );
    },
    redoAudit(data) {
      this.$confirm("您确定要取消该收/退款明细项的审核吗？", "操作确认", {
        type: "warning",
      }).then((_) => {
        rollbackAudit(data.id).then((res) => {
          this.$notify({
            title: "取消审核成功",
            type: "success",
            duration: 2500,
          });
          this.toQuery();
          this.$emit("pay-completed");
        });
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>